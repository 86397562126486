.presentation{
  .block{
    margin-bottom: 15px;
  }
  .panel{
    font-size: 120%;

  }
  .content{
    min-height: 620px;
  }
  .border{

    @include border-radius(10px);
    border: 1px solid $gray-light;
    img{
      width: 90%;
      max-width: 350px;
      margin: 5px;
      text-align: center;
    }
    .block_1{
      font-size: 140%;
      margin-left: 15px;
      margin-bottom: 20px;
    }
    .block_2{
      padding: 10px;
      h4{
        font-size: 16px;
      }
    }
    .block_3{

      ul{
        margin-top: 10px;
        margin-left: 0px;
        padding-left: 10px;
        li{
          margin-bottom: 5px;
        }
      }
    }
  }
  .color-papercut{
    color:#8DC73F;
  }
  .color-bluemega{
    color:$blueMega;
  }
  .color-mintdoc{
    color:#69B256;
  }
  .footer{
    padding: 10px 5px;
    text-align: center;
    a{
      font-size: 140%;
    }

  }
}