/*
 * Namespace DTCR - "DataTables ColReorder" plug-in
 */

table.DTCR_clonedTable {
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 202;
}

div.DTCR_pointer {
  width: 1px;
  background-color: #0259C4;
  z-index: 201;
}
