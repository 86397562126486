$blueMega:                  #0A9EDA;
$blueMegaDark:              #0E2A40;
$blueMegaLight:             #1C537F;
$blueMegaLighest:           #2A7DBF;
@import "variables";
@import "mixin";
@import "../vendor/styles/smartadmin-production";


@import "bootstrap";
@import "select2";

@import "menu";


@import "border";

@import "shortcut";
@import "form";
@import "plugins";
@import "plugins/_plugin-smart-notifications";
@import "plugins/_animation-engine";
@import "library/fontawesome/font-awesome";
@import "smartadmin/_main-special-class";
//@import "overwrite-floats";
/*
@import "plugins/_plugin-dataTables.bootstrap";
@import "plugins/_plugin-dataTables.colReorder";
@import "plugins/_plugin-dataTables.colVis";
@import "plugins/_plugin-dataTables.responsive";
@import "plugins/_plugin-dataTables.tableTools";
@import "plugins/_plugin-dataTables-cust";
*/

.fa-1_5X {
  font-size: 1.9rem;
}

@import "library/flags/flags";
@import "nestable";
@import "login";
@import "header";
@import "tree_theme";
@import "home";
@import "bluemega";



