/*!
 * SmartAdmin - Responsive WebApp
 *
 * Copyright 2014 bootstraphunter.com
 * Designed and built with all the love in the world by @bootstraphunt.
 */
// Core variables and mixins
@import "bootstrap/bootstrap/mixins";
// Plugins have been moved to "smartadmin-production-plugins.less" file (because IE cannot handle more than 4096 selectors in a stylesheet)
// Libraries (Remove if not needed)
@import "library/animation/animation-engine";
//@import "library/jqueryui/jquery-ui-1.10.3.custom";
@import "library/flags/flags";
@import "library/cursors/cursors";
// Individual pages, sections, and components.  You can remove any of these sections you don't use
// Components
@import "smartadmin/plugin-jcrop";
@import "smartadmin/plugin-superbox";
@import "smartadmin/plugin-colorpicker";
//@import "smartadmin/component-fullscreen";
//@import "smartadmin/component-jqueryui"; // dependency: jquery-ui-1.10.3.custom
//@import "smartadmin/component-pricingtable";
//@import "smartadmin/component-pace";
//@import "smartadmin/component-smartchat";
//@import "smartadmin/component-smartforms";
// Pages
//@import "smartadmin/page-error";
//@import "smartadmin/page-extr";
// Sections
@import "smartadmin/section-nestables";
//@import "smartadmin/section-calendar";
//@import "smartadmin/section-inbox";
//@import "smartadmin/section-profile";
@import "smartadmin/section-search";
//@import "smartadmin/section-todo";
//@import "smartadmin/section-timeline";
//@import "smartadmin/section-forum";
@import "smartadmin/section-ajax-msg";
//@import "smartadmin/section-projects";
// Main THEME (Imports by order - do not change order)
@import "smartadmin/main";
@import "smartadmin/main-responsive";
// Patches
@import "smartadmin/ie-fix";
// UI (overrides and new)
@import "smartadmin/top-menu";
@import "smartadmin/no-menu";
// Overrides
@import "overrides";

.tree {
  min-height: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px
}

.tree li {
  list-style-type: none;
  margin: 0;
  //padding: 5px;
  position: relative
}

.tree ul ul li:hover {
  background: rgba(0, 0, 0, .015)
}

.tree li:after, .tree li:before {
  content: '';
  left: -20px;
  position: absolute;
  right: auto
}

.tree li:before {
  border-left: 1px solid #999;
  bottom: 50px;
  height: 100%;
  top: -11px;
  width: 1px;
  -webkit-transition: "border-color 0.1s ease 0.1s";
  -moz-transition: "border-color 0.1s ease 0.1s";
  -o-transition: "border-color 0.1s ease 0.1s";
  transition: "border-color 0.1s ease 0.1s"
}

.tree li:after {
  border-top: 1px solid #999;
  height: 20px;
  top: 18px;
  width: 25px
}

.tree li span {
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border: 1px dotted #999;
  border-radius: 5px;
  display: inline-block;
  padding: 3px 8px;
  text-decoration: none;
  -webkit-transition: color .2s ease .1s, background-color .2s ease .1s, border-color .3s ease .2s;
  -moz-transition: color .2s ease .1s, background-color .2s ease .1s, border-color .3s ease .2s;
  -o-transition: color .2s ease .1s, background-color .2s ease .1s, border-color .3s ease .2s;
  transition: color .2s ease .1s, background-color .2s ease .1s, border-color .3s ease .2s
}

.tree li.parent_li > span {
  cursor: pointer;
  padding: 7px
}

.tree > ul > li::after, .tree > ul > li:before {
  border: 0
}

.tree li:last-child::before {
  height: 30px
}

.tree li.parent_li > span:hover, .tree li.parent_li > span:hover + ul li span {
  background: #eee;
  border: 1px solid #94a0b4;
  color: #000
}

.tree > ul {
  padding-left: 0
}

.tree ul ul {
  padding-left: 34px;
  padding-top: 10px
}

.tree li.parent_li > span:hover {
  background-color: #DF8505;
  border: 1px solid #C67605;
  color: #fff
}

.tree li.parent_li > span:hover + ul li::before {
  border-left-color: #F89406
}

.tree li.parent_li > span:hover + ul li::after {
  border-top-color: #F89406
}

.tree li.parent_li > span:hover + ul li span {
  background: #FDDFB3 !important;
  border: 1px solid #FAA937;
  color: #000
}

.tree .fa.icon-minus-sign:before {
  content: "\f056" !important
}

.tree .fa.icon-plus-sign:before {
  content: "\f055" !important
}