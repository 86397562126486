/** Login **/
.main_login {
  background-color: #F7F7F7;
}
html.main_login, html.main_login body,body.main_login{
  background: none;
  background-color: #F7F7F7;
}

.login {
  width: 800px;
  height: 439px;
  margin: 0px auto;
  margin-top: 80px;
  background: url('../img/login-background.jpg') no-repeat 100%;
  h1{
    text-align: center;
    margin-bottom: 25px;
  }
  section {
    width: 480px;
    position: relative;
    margin: 0px auto;
    margin-top: 140px;
    .login_email{
      margin-top: 2px;
    }
    .form-control{
      margin-bottom: -2px;
    }
    input {
      background: none;
      color:#FFF;
      border: none;
    }

    color: #FFF;
    .bg_input {
      position: relative;
      background: url('../img/login_bg_input.jpg') no-repeat;
      background-size: 280px;
      //background-position: right;
      background-position: 160px 0px;
      height:350px;
    }
    .login_submit{
      margin: 0px;
      padding: 0px;
      border: none;
      position: absolute;
      top:15px;
      right:10px;
      background: none;
    }
  }
}