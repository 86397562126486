.tree_themes {
  text-align: center;
  margin-bottom: 15px;
  clear: both;
  ul {
    margin-left: 20px;
    margin-top: 10px;

    li {
      position: relative;
      width: 250px;

      text-align: center;
      &:hover {
        //cursor: pointer;
        
        /*
        div {
          display: block;
          @include transition(all .5s ease-in)
        }*/
      }
      display: inline;
      float: left;
      margin-right:5px;
      padding: 0px 20px 0px 10px;
      border-right: 1px solid #6e7781;

      img {
        width: auto !important;
        height: 55px;
      }
      span.icon {
        position: absolute;
        bottom: 0px;
        right: 50%;
        margin-top: -5px;
        img {
          width: 15px;
          height: 9px;
        }
      }
      &:last-child {
        border: none;
      }

      // background: url('/assets/img/icon/menu_icon_child.png') no-repeat 50% 80%;
      div {
        //position: absolute;
        //top: 60px;
        //display: none;
        //float:left;

        ul {
          background-color: rgba(255, 255, 255, 1);
          &:before {
            border: none;
          }
          margin: 0px;
          padding: 0px;
          //border: 1px solid #555F6A;
          li {
            text-align: left;
            &:before {
              border: none;
            }
            border-radius: 0px;
            display: block;
            padding: 4px 0px 4px 0px;
            margin: 0px;
            background: none;
            border: none;
            &:hover {
              color: #111;
              i {
                color: #111;
              }
              span {
                color: #111;
              }
            }

            a {
              padding: 0 0 5px 10px;
            }
          }
        }
      }

    }

  }
  div ul li a:active {
    background: none !important;
  }
}