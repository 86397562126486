.dd3-handle {
  width: 3px;
  padding: 8px 4px;
  &:before {
    content: '';
  }
}

.dd3-item > button {
  margin-left: 15px;
}

.dd3-content {
  padding-left: 15px;

}

.settingsPost {
  width:15px;
  &:hover {
    .listSettings {
      display: block;

    }
  }
}

.listSettings {
  background-color: #FFF;
  border: 1px solid $blue;
  min-width: 350px;
  z-index: 999;
  display: none;
  ul {
    margin: 0px;
    padding: 0px;
    li {
      //text-align: center;
      margin: 2px;
      border-bottom: 1px solid $gray-lighter;
      padding: 3px;
      &:hover{
        color:$black;
      }
    }
  }
  position: absolute;
  left: 15px;
  top: -7px;

}