label {
  font-weight: 400;
}

.form-control {
  display: block;
  width: 100%;
  height: 32px;
  padding: 6px 12px;
  font-size: 13px;
  line-height: 1.42857143;
  color: $gray;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.btn-checkbox {
  padding: 4px 10px 4px 10px;
}

/**
* Dashboard
*/
.date_order {
  line-height: 20px;
  input {
    height: 28px;
    margin-top: 2px;
    color: $black;
    margin-left: 5px;
    margin-right: 10px;
  }
  .btn {
    margin-top: -2px;
    line-height: 20px;
  }
}

/*
 * Card component
 */
.card {
  position: relative;
  background-color: #F7F7F7;
  /* just in case there no content*/
  padding: 0px;
  padding-bottom: 20px;
  margin: 0 auto 25px;
  margin-top: 50px;
  /* shadows and rounded borders */
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.addmultiperm .row {

  margin-bottom: 5px;
  padding: 0px;

}

.permissions {
  margin-top: 20px;
  li {
    list-style-type: none
  }
  ul {
    padding-left: 10px;
    min-height: 80px;
  }
}

.tab-pane {
  padding-top: 20px;
}

.addoncustom {
  .input-group {
    margin-bottom: 10px;
  }
  .input-group-addon {
    padding: 2px 12px;
    margin-bottom: 15px;
  }
}

.qty {
  width: 60px;
}

.selectqty {
  width: 90%;
}

.table tbody td.labelinput {
  padding-top: 12px;
}

/** TOGGLE **/
/*
 * toggles
 */

.toggle {
  margin-bottom: 4px;
  //padding-right: 31px;
  font-size: 15px;
  //line-height: 25px;
  color: #404040;
  cursor: pointer;
  box-sizing: content-box;

  &:last-child {
    margin-bottom: 0;
  }

  input {
    position: absolute;
    left: -9999px;
    &:checked + i:after {
      content: attr(data-swchon-text);
      text-align: right;
    }
    &:checked + i:before {
      right: 36px;
    }
  }

  i {
    content: '';
    position: absolute;
    top: 12px;
    right: 100px;
    display: block;
    width: 49px;
    height: 17px;
    border-width: 1px;
    border-style: solid;
    border-radius: 12px;
    background: #fff;
    box-sizing: content-box;
    &:after {
      content: attr(data-swchoff-text);
      position: absolute;
      top: 2px;
      right: 8px;
      left: 8px;
      font-style: normal;
      font-size: 9px;
      line-height: 13px;
      font-weight: 700;
      text-align: left;
      color: #5f5f5f;
    }
    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 4px;
      right: 4px;
      display: block;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      opacity: 1;
      transition: right 0.2s;
      -o-transition: right 0.2s;
      -ms-transition: right 0.2s;
      -moz-transition: right 0.2s;
      -webkit-transition: right 0.2s;
      background-color: $red;
    }
  }
}

.toggle input:checked + i {
  border-color: #3276B1;
}

.progress {
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
  height: 22px;
  background: $gray-lighter;
  box-shadow: 0 1px 0 transparent, 0 0 0 1px lighten($gray-light, 29%) inset;
  -webkit-box-shadow: 0 1px 0 transparent, 0 0 0 1px lighten($gray-light, 29%) inset;
  -moz-box-shadow: 0 1px 0 transparent, 0 0 0 1px lighten($gray-light, 29%) inset;

  border-radius: $progressbar-radius;
  -moz-border-radius: $progressbar-radius;
  -webkit-border-radius: $progressbar-radius;
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 11px;
  color: $white;
  text-align: center;
  background-color: $blue;
  -webkit-box-shadow: inset 0 -1px 0 rgba(red($black), green($black), blue($black), 0.15);
  box-shadow: inset 0 -1px 0 rgba(red($black), green($black), blue($black), 0.15);
  font-weight: bold;
  text-shadow: 0 -1px 0 rgba(red($black), green($black), blue($black), 0.25);
  background-image: url("#{$base-url}/pattern/overlay-pattern.png");
}

.percent {
  font-size: 14px;

}

.icon {
  position: relative;

}

.ajax_loader {
  background-size: 25px;
  background-image: url('../img/green_loader.gif');
  background-repeat: no-repeat;
  background-position: 98% 46%
}

.ajax_loader2 {
  background-size: 25px;
  background-image: url("../img/loading2-128.gif");
  background-repeat: no-repeat;
  background-position: 98% 46%
}

.ls_container {
  position: relative;
}

.ls_result_div {
  border: 1px solid $gray-light;
  margin-right: 23px;
  padding: 5px;
  position: absolute;
  z-index: 1001;
  width: 100%;
  background-color: $white;
  margin-top: 5px;
  display: none;
  @include transition(all .4s ease-in-out 0s);
  h3 {
    color: $blueMega;
    font-size: 1.5rem;
    a:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

img.translate {
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
}

.search-results {
  padding: 5px;
}

/** SEARCH ALL */
.search_all {
  input {
    background: #FFF;
  }
  .search-results {
    height: 300px;
    overflow: scroll;
    overflow-x: hidden;

    h5 {
      font-weight: bold;
    }
    h6 {
      margin-left: 15px;
    }
    li {
      color: $blueMega;
    }
  }
}

.leftselect {
  margin-top: 10px;
  margin-left: 5px;
  & > i{
    margin-left: 5px;
  }
}