/*
	Important this file is beeen modified by Webarch
*/

/*------------------------------------*\
    SuperBox
\*------------------------------------*/
.superbox *,
.superbox *:after,
.superbox *:before {
  margin: 0;
  padding: 0;
  /*
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  */
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

/* SuperBox */
.superbox-img {
  max-width: 100%;
  width: 100%;
  cursor: pointer;

}
.superbox-list {
  display: inline-block;
  *display: inline;
  zoom: 1;
  width: 150px;
  height:150px;
  margin: 5px;
  position: relative;


  .cursor{
    display: none;
    position: absolute;
    left: 40%;
    bottom: -16px;
    z-index: 2;
    text-align: center;
    font-size: 40px;
    color: #222222;
    z-index: 9999;
    width:20px;
  }

  &.picture .cursor{
    bottom: -40px;
  }
  &:hover, &.active{
    .cursor{
      display: block;
    }
  }


  &.active,&:hover{
    h4 {

      @include transition(0.8s);
      background-color: rgba(0, 0, 0, .8);
    }

    img{
      //opacity: 0.8;
    }
    .text{
      font-weight: 700;
    }
  }
  h4{
    position: absolute;
    bottom: 0%;
    -webkit-transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
    left: 50%;
    @include transition(0.8s);
    min-height:40px;
    //line-height: 90%;
    width:100%;
    font-size:14px;
    text-align: center;
    background-color: rgba(0,0,0,.5);
    padding: 10px 0px;
    color:$white;
    opacity: 1;
    cursor: pointer;


  }
  img {
    width: 100%;
  }
  .view {

    text-align: center;
    .icon {

      text-align: center;
      cursor: pointer;
    }
    .text{
      position: absolute;
      //bottom:0px;
      left:0px;
      right:0px;
      text-align: center;
      height:50px;
      cursor: pointer;
      font-size:14px;
      &:hover{
        font-weight: 700;
      }

    }
    h3{
      font-size:13px;
    }
  }

}



.superbox-show {
  text-align: left;
  position: relative;
  border:10px solid $gray-dark;
  @include border-radius(10px);
  width: 100%;
  float: left;
  padding: 25px 0 12px 25px;
  display: none;
  margin: 0px 0;
}

.superbox-current-img {

  padding-right: 25px;
  padding-bottom: 13px;
}

.superbox-float {
  float: left;
}

.superbox-close {
  opacity: 0.7;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  transition: all 0.1s linear 0s;
  &:hover {
    opacity: 1;
  }
}

.superbox-imageinfo {
  display: inline-block;
  max-width: 500px;
  color: #fff;
  //padding: 0 25px 0 0;
  span {
    font-size: 13px;
    color: lighten($asideColor-start, 45%);
    margin-right: -2px;
    padding-top: 10px;
    display: inline-block;
    //padding-bottom: 13px;
    p:last-child {
      margin-bottom: 10px;
      //margin-top: 30px;
    }
  }

  > :first-child {
    margin-bottom: 10px;
    font-weight: bold;
  }
}

.superbox .upload {
  margin-right: 10px;
  line-height: 30px;
  &.dotted {
    border: 3px dashed $gray;
  }
  height: 30px;
}
/*
@media only screen and (min-width: 320px) {
  .superbox-list {
    width: 48%;
  }
}

@media only screen and (min-width: 486px) {
  .superbox-list {
    width: 49.5%;
  }
}

@media only screen and (min-width: 768px) {
  .superbox-list {
    width: 31.9%;
  }
}

@media only screen and (min-width: 1025px) {
  .superbox-list {
    //width: 19.6%;
    width: 10%;
    margin-top:10px;
    &.picture {
      margin:0px;

    }
    &.active:before {
      left: 43%;
      bottom: -34px;
      font-size: 30px;
    }
    &.active.picture:before{
      bottom: -22px;
    }
  }
}

@media only screen and (min-width: 1824px) {
  .superbox-list {
    width: 15%;
  }
}


@media only screen and (min-width: 320px) {
  .superbox-list {
    width:50%;
  }
}
@media only screen and (min-width: 486px) {
  .superbox-list {
    width:25%;
  }
}
@media only screen and (min-width: 768px) {
  .superbox-list {
    width:16.66666667%;
  }
}
@media only screen and (min-width: 1025px) {
  .superbox-list {
    width:12.5%;
  }
}
@media only screen and (min-width: 1824px) {
  .superbox-list {
    width:12.5%;
  }
}
*/