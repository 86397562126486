@import url(https://fonts.googleapis.com/css?family=Lato:400,700);

html {
  background: url('/assets/img/main_footer.jpg')  #F7F7F7;
  background-repeat: no-repeat;
  background-position: bottom center;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  //background: url("/assets/img/mybg.png") #fff;
  direction: ltr;
  font-family: 'Lato', "Open Sans", Arial, Helvetica, Sans-Serif;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  background: url("../img/main_header.jpg")  #F7F7F7;
  background-repeat: no-repeat;
  background-position: top center;
}

#main {
  margin-left: 0px;
  padding: 0;
  margin-bottom: 100px;
  //min-height: 500px;
  position: relative;
  background: none;

  //min-height: 800px;

}
#content{
  background: none;
}

#main_fluid {
  //min-height: 800px;
}

h1 {
  letter-spacing: -1px;
  font-size: 24px;
}

.bg-color-red {
  background-color: $red !important;
}

.error {
  color: $red
}

.alert-heading {
  margin: 0;
  color: inherit;
  line-height: normal;
  font-weight: 800;
}

.padtop20 {
  padding-top: 20px;
}

.pull-right {
  float: right !important;
}

.inline-block {
  float: none;
  display: inline-block;
  margin: 0 -0.125em;
  vertical-align: top;

}

.clear {
  clear: both;
}

.dropdown-menu {
  min-width: inherit;
  li a:hover {
    color: #3276b1;
  }
}

.ul_inline .btn-xs {
  padding: 1px 5px 0px 5px;
  line-height: inherit;
}

.btn-xs {
  padding: 2px 5px 0px;
}

.nodisplay {
  display: none;
}

.panel-bluemega {
  .panel-heading {
    color: #fff;
    background-color: $blueMega;
    border-color: $blueMega;
  }
  border-color: $blueMega;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.ul_inline {
  li {
    display: inline;
    margin-right: 15px;
  }
}

.bold {
  font-weight: bold;
}

.normal {
  font-weight: normal;
}

.font100 {
  font-size: 100%;
}

.w100 {
  width: 100%;
}

.table-responsive {
  overflow-x: inherit;
}

.alert {
  margin-bottom: 20px;
  margin-top: 0;
  border-width: 0;
  border-left-width: 5px;
  padding: 10px;
  border-radius: 0;
}

.clearfix {
  clear: both;
}

.wrapper {
  padding: 10px;
}

.pointer {
  cursor: pointer;
}

.borderleft {
  border-left: 1px solid $gray-light;
}

.borderright {
  border-right: 1px solid $gray-light;
}

.fa.pointer {
  cursor: pointer;
  &:hover {
    font-weight: bold;
    font-size: 14px;
  }
}

.fa.pointerB {
  cursor: pointer;
  &:hover {
    font-weight: bold;
    font-size: 2rem;
  }
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

ul li {
  list-style-type: none;
}

// Danger and err<or appear as red
.btn-bluemega {
  @include button-variant($btn-bluemega-color, $btn-bluemega-bg, $btn-bluemega-border);
}

.btn-nano {
  padding: 1px 3px;
  margin-left: 3px;
  i {
    font-size: 10px;
  }
}

.btn-border {
  padding: 1px 3px;
  border: 1px solid $white;
}

#search-mobile {
  display: none;
}

#ribbon {
  min-height: $ribbon-height;
  background: transparent;
  color: $gray;
  padding: 0 13px;

  position: relative;
  .information {
    margin: 0;
    color: $white;
    padding: 11px 34px 11px 0px;
    //line-height: 28px;
    .listpermissions {
      margin-top: -2px;
      padding-top: 3px;

    }
    a {

    }
  }

}

ul.summary {
  margin-left: 0px;
  padding-left: 10px;
  li {
    margin: 5px;
    font-size: 14px;
    &.root {
      margin-left: -20px;

    }
    ul {
      padding-left: 20px;
      margin-bottom: 10px;
    }
  }
  margin-bottom: 50px;
}

#content {
  padding: 10px 14px;
  position: relative;
  section .bgborder {
    background-color: #fff;
    border: 1px solid $blueMegaLighest;
    margin-bottom: 10px;
  }
  .header_title {
    // border-bottom: 1px dashed rgba(0, 0, 0, .2);
    margin: 0px 0px 20px;
  }

  .page-title {
    h1 {
      padding: 0px;
      font-size: 16px;
      font-weight: 400;
      color: #222;
      margin: 0px;
    }

  }

}

.link_customer.nav-pills li a {
  padding: 5px 10px;
  font-size: 14px;
}

li a.btn-primary {
  background-color: $white;
  i.fa {
    color: $brand-primary;
  }
}

li.active a.btn-primary {
  background-color: $brand-primary;
  i.fa {
    color: $white;
  }
}

.information a {
  color: $white;
}

.products .table > tbody > tr > td, .products .table > tfoot > tr > td {
  padding: 2px;
}

a.bg-color-green:hover {
  background-color: darken($green, 6.5%) !important;
}

a.bg-color-magenta:hover {
  background-color: darken($magenta, 6.5%) !important;
}

tbody.lineheight49 td {
  line-height: 49px;
}

.ref {
  width: 100px;
}

.pht {
  width: 90%;
}

.unitht {
  width: 120px;
}

.table > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
  vertical-align: middle;
}

.trtotal {
  width: 90px;
  text-align: right;
}

.ttht {
  font-weight: bold;
  font-size: 15px;
}

.ttc {
  font-weight: bold;

}

.sstable {
  padding-right: 12px;
}

/*
 * Margins
 */
.no-margin {
  margin: 0px !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.margin-top-0 {
  margin-top: 0px !important
}

.margin-bottom-0 {
  margin-bottom: 0px !important
}

.padding-10 {
  padding: 10px !important;
}

.ajax-dropdown, .ajax-dropdown-permissions {
  position: absolute;
  z-index: 905;
  top: 48px;
  right: 16px;
  width: 344px;
  height: 435px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -webkit-box-shadow: 0 2px 4px rgba(30, 30, 100, .25);
  -moz-box-shadow: 0 2px 4px rgba(30, 30, 100, .25);
  box-shadow: 0 2px 4px rgba(30, 30, 100, .25);
  background: #fff;
  border: 1px solid #b3b3b3;
}

.getPermissions {
  color: $black;
  h3 {
    font-size: 110%;
    font-weight: bold
  }
  ul {
    min-height: 90px;
    margin: 0px;
    padding-left: 0px;
  }
}

.custom-scroll::-webkit-scrollbar-track-piece {
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  -webkit-border-radius: 0;
  background-color: transparent;
  border-radius: 0;
}

.custom-scroll::-webkit-scrollbar {
  -webkit-overflow-scrolling: touch;
  height: 10px;
  width: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  -moz-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07);
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07);
  background-clip: padding-box;
  background-color: rgba(0, 0, 0, .2);
  border: none;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07);
}

.notification-body {
  padding: 0 0 0 3px;
  margin: 0;
  list-style: none;
}

.notification-body *, .notification-body:after *, .notification-body:before * {
  box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
}

.notification-body > li > span {
  background: #fff;
  display: block;
  min-height: 25px;
  overflow: hidden;
  padding: 8px 10px 13px;
  white-space: normal;
}

.notification-body .me {
  background: #FFFFE0;
}

.feedback {
  background-color: $gray;
  @include border-radius(3px);
  z-index: 1999;
  position: fixed;
  bottom: 0px;
  right: 0px;
  margin-right: 10px;

  width: 250px;
  .feed_header {
    display: block;
    cursor: pointer;
    background-color: $red;

    padding: 0px 5px;
    @include border-radius(3px);
    a {
      color: $white;
    }
    a.feed_link {
      float: left;
    }
    a.feed_button {
      float: right;
    }
  }
  .feed_form {
    //min-height:200px;
    padding: 10px;
    margin-bottom: 10px;
  }
  .formloading {
    height: 100%;
    img {
      top: 40%;
    }
  }
}

.page-footer {
  height: 40px;
  padding: 6px 13px 0px 20px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  display: block;
}

.popover {
  width: 200px;
  text-align: center;

}

/**
* ACCORDIONS
*/

.smart-accordion-default.panel-group {
  margin-bottom: 0px;
}

.smart-accordion-default input {
  margin-top: 2px;
}

.smart-accordion-default.panel-group .panel + .panel {
  margin-top: -1px;
}

.smart-accordion-default.panel-group .panel-heading {
  padding: 0px;
}

.smart-accordion-default.panel-group .panel-title a, .smart-accordion-default.panel-group .panel-title span {
  display: block;
  padding: 10px 15px;
  text-decoration: none !important;
}

.smart-accordion-default .panel-heading, .panel-group .panel {
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
}

.smart-accordion-default .panel-default > .panel-heading {
  background-color: lighten($gray-lightest, 3.5%);
}

.smart-accordion-default .panel-default {
  border-color: lighten($gray-light, 16.5%);
}

#pendingModal .pending {
  position: relative;
  min-height: 400px;
  div {

    @include all-align();
  }
}

.shortcut {
  h3 {
    margin: 5px;
    background-color: $blueMega;
    color: $white;
    padding: 4px;
    text-align: center;
    font-weight: 300;
    margin-bottom: 10px;
    cursor: pointer;

  }
  ul {

    @include transition(color .3s ease);
    display: block;
    @include bp-small {
      display: none;
      margin: 0 auto 0;
      width: 300px;
      li {

        display: block;
        margin-bottom: 5px;
      }
    }
  }
}

a.iconhover {
  color: $gray;
  &:hover {
    color: $brand-warning;
    cursor: pointer;
  }
}

.showfiles {
  margin-top: 15px;
  .block {
    border: 3px solid;
    @include box-shadow(3px 3px 25px 0px #656565);
    margin: 15px 20px 30px 20px;
    min-height: 100px;
    position: relative;
    &:hover {
      @include box-shadow(3px 3px 25px 5px #656565);
      cursor: pointer;

      .delfile {
        display: block;

        &:hover {
          font-size: 16px;
        }
      }
    }
    a.iconfile {
      z-index: 999;
      position: absolute;
      top: -20px;
      left: -35px;
      width: 50px;
      height: 50px;
      display: block;
      line-height: 40px;
      background-color: $white;
      border: 3px solid $gray-light;
      color: $gray;
      @include border-radius(2px);
      font-size: 30px;
      &:hover {
        color: $gray-dark;
      }
    }

  }
  .link span.iconfile:hover {
    color: $gray;
  }
  .superbox_file {

    .delfile {
      display: none;
      margin: 2px;
      cursor: pointer;

    }

    .content_file {

      padding: 10px;
      height: 100%;
      padding-left: 40px;
      padding-right: 15px;
      padding-bottom: 25px;
      h4 {
        font-size: 15px;
        margin-bottom: 10px;
        font-weight: bold;

      }

    }
    .date {
      font-size: 10px;
      color: $gray-dark;
    }

    div {
      color: $gray;
    }
  }
}

/*
@media only screen and (min-width: 320px) {
  .superbox_file {
    width: 48%;
  }
}

@media only screen and (min-width: 486px) {
  .superbox_file {
    width: 49.5%;
  }
}

@media only screen and (min-width: 768px) {
  .superbox_file {
    width: 32.9%;
  }
}

@media only screen and (min-width: 1025px) {
  .superbox_file {
    width: 19.6%;
  }
}

@media only screen and (min-width: 1824px) {
  .superbox_file {
    width: 15%;
  }
}*/

.viewPost {
  &:hover {
    color: $gray-dark;
    font-weight: bold;
  }
}

.tree_manual {
  span {
    cursor: pointer;
  }
  ul {
    margin-left: 10px;
    padding-left: 10px;
    li {
      cursor: pointer;
      padding: 3px;
      ul {
        margin-bottom: 5px;
      }
    }
  }
  a.used{
    font-weight: bold;
    font-style: italic;
  }
}

.dd-handle {
  padding-right: 5px;
}

.viewArticle {
  ul li {
    list-style-type: circle;
  }
  padding-right: 5px;
  img {
    height: auto;
    max-width: 100%;
  }
  div {
    margin-left: 15px;
  }
  h1 {
    font-size: 300%;
    color: $blueMega;

  }
  h3 {
    margin-top: 5px;

    padding: 15px 15px;
    color: $white;
    background-color: $blueMega;

  }
  h3 > h3 {
    font-size: 13px;
    background-color: inherit;
  }
  h4 {
    color: $blueMega;
    text-decoration: underline;
  }
}

/** Movies **/
.addoncustom {
  margin-bottom: 15px;
}

.movie {
  min-height: 230px;
  margin-bottom: 10px;
  & > div {
    position: relative;
    box-shadow: 0 0 5px rgba(0, 0, 0, .25);
    padding: 4px;
    overflow: hidden;
  }
  .thumb {

    .thumb-int {
      overflow: hidden;
      max-width: 100%;
      background-color: $black;
      .vignettage {
        position: absolute;
        width: calc(100% - 8px);
        left: 0px;
        top: 0px;
        box-shadow: none;
        margin: 4px;
        z-index: 1;
        @include transition(all .4s ease-in-out 0s);
        opacity: 0;

        cursor: pointer;
        .voir-video {

          border-bottom: 1px solid #fff;
          margin-bottom: 10px;
          font-size: 1.4em;
          font-weight: bold;
          text-align: center;
          padding: 15px 0px;

          a {
            color: $white;
            &:hover {
              color: $gray;
            }
          }
        }
        .short_description {
          padding: 8px;
          color: $gray-light;

        }
      }
      img {
        width: 100%;
        @include transition(all .4s ease-in-out 0s);
      }
    }
    &:hover {
      .thumb-int, .vignettage {
        opacity: 1;
        img, .nopicture {
          @include transition(all .4s ease-in-out 0s);
          opacity: 0;
        }
      }
    }

  }
  .item-title {
    margin-top: 10px;
    h3 {
      margin: 0;
      padding: 0;
      text-decoration: none;
      height: 40px;
      a {
        text-decoration: none;
        font-size: 13px;
        overflow-y: hidden;
        font-weight: bold;
      }
    }

    .release {
      text-align: right;
      font-size: 14px;
      color: #636363;
      margin-top: -17px;
      //width: 70px;
      padding-left: 15px;
      font-weight: normal;
    }
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed,
.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.onlymovie {
  .header {
    border: 1px solid $gray-lighter;
    padding: 0 5px;
    height: 40px;
    background-color: $blueMega;
    color: $white;
    h3 {
      margin: 0px 10px;
      line-height: 40px;
    }
    div {
      line-height: 35px;
    }
    clear: both;
  }
  .content {

    margin-top: 15px;

    border: 1px solid $gray-lighter;
    padding: 15px;
    h4 {
      color: $blueMega;
      font-weight: bold;
      font-size: 2rem;
    }
    .resume {
      min-height: 600px;
    }
  }
}

.sk-folding-cube {
  margin: 20px auto;
  margin-bottom: 40px;
  width: 100px;
  height: 100px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $gray-dark;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}

.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}

.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}

.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

video {
  width: 100%;
  height: auto;
  max-height: 100%;
}

.upload {
  border: 1px dashed $gray-dark;
}

.fondutext {
  filter: alpha(opacity=100);
  opacity: 1;
  @include transition (opacity 1s ease-in-out);
}

.fondutext.opacity0 {
  filter: alpha(opacity=10);
  opacity: 0.1;
  @include transition (opacity 1s ease-out-in);
}

.modal-all {
  width:95%;
}
#translateList{
  div {
    height: 100px;
    background-color: #ABCDEF;
    font-family: Verdana, Arial, Sans-Serif;
    font-size: 1em;
    text-align: center;
  }
}