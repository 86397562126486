/*
 * No Padding
 */

.no-content-padding {
	margin: -10px -14px 0 -14px; /*used with content >:first-child */
}

.no-padding {
  padding: 0px !important;
  > pre {
    margin: 30px;
  }
}
.no-padding > table, .no-padding .dataTables_wrapper table {
  border: none !important;
  margin-bottom: 0px !important;
  border-bottom-width: 0px !important;
}
.no-padding-bottom {
  padding-bottom: 0px !important;
}

.padding-5 { padding: 5px !important; }
.padding-7 { padding: 7px !important; }
.padding-10 {	padding: 10px !important; }
.padding-gutter {	padding: $grid-gutter-width/2 !important; }
.padding-top-10 {	padding-top: 10px !important; }
.padding-top-15 {	padding-top: 15px !important; }
.padding-bottom-10 { padding-bottom:10px !important; }
.padding-bottom-0 { padding-bottom: 0px !important; }
.padding-top-0 { padding-top: 0px !important; }

.no-padding > table tr td:last-child,.no-padding > table tr th:last-child,.no-padding .dataTables_wrapper table tr td:last-child,.no-padding .dataTables_wrapper table tr th:last-child {
  border-right: none !important;
}

.no-padding > table tr td:first-child,.no-padding > table tr th:first-child,.no-padding .dataTables_wrapper table tr td:first-child,.no-padding .dataTables_wrapper table tr th:first-child {
  border-left: none !important;
}

.no-padding {
  .dataTables_wrapper {
    table {
      tbody {
        tr:last-child {
          td {
            border-bottom: 0;
          }
        }
      }
    }
  }
}


/*
 * Font Weights
 */
.semi-bold { font-weight: 400 !important; }
.ultra-light { font-weight: 300 !important; }

/*
 * Font Sizes
 */
.font-xs { font-size: 85% !important; }
.font-sm { font-size: 95% !important; }
.font-md { font-size: 130% !important; }
.font-lg { font-size: 160% !important; }
.font-xl { font-size: 200% !important; }
.font-400 { font-size: 400% !important; }

/*
 * Margins
 */
.no-margin { margin: 0px !important; }
.margin-top-5 { margin-top: 5px !important; }
.margin-top-10 { margin-top: 10px !important; }
.margin-bottom-5 { margin-bottom: 5px !important; }
.margin-bottom-10 { margin-bottom: 10px !important; }
.margin-right-5 { margin-right: 5px !important; }
.margin-top-0 { margin-top: 0px !important }
.margin-bottom-0 { margin-bottom: 0px !important }

/*
 * No border
 */
.no-border, .well[class*=" bg-"].no-border {
	border-width: 0px !important;
}

.no-border-transparent {
	border-color: transparent !important;
}

.no-border-radius {
	border-radius: 0px;
	-moz-border-radius: 0px;
	-webkit-border-radius: 0px;
}

/*
 * Inlines
 */
.display-inline { display: inline-block !important; }
.display-normal { display: inline !important; }

/*
 * CURSORS
 */
.cursor-pointer { cursor: pointer; }

/*
 * Images
 */
.hover-transparent { opacity: .5; }
.hover-transparent:hover { opacity: 1; }

/*
 * Forced text alignments
 */
.text-align-center, .text-center { text-align: center !important }
.text-align-left, .text-left { text-align: left !important }
.text-align-right, .text-right { text-align: right !important }

/*
 * HR
 */
hr.simple {
	margin-top: 10px;
	margin-bottom: 10px;
	border-style: dashed;
}

/*
 * SHORT CUTS
 */
.pull-right { float: right !important; }
.pull-left { float: left !important; }

/*
 * Air fields
 * Description: absolute positioned objects
 */
.air {
	position: absolute;
	z-index: 5;
}

.air-bottom {
	bottom: 0px;
}

.air-top {
	top: 0px;
}

.air-top-right {
	top: 0px;
	right: 0px;
}

.air-top-left {
	top: 0px;
	left: 0px;
}

.air-bottom-left {
	bottom: 0px;
	left: 0px;
}

.air-bottom-right {
	bottom: 0px;
	right: 0px;
}
.air-bottom-right-10 {
  right:10px;
  bottom:5px;
}
.air-bottom-right-20 {
  right:20px;
  bottom:5px;
}

/*
 * Display none
 */

.display-none {
  display:none !important;
}
