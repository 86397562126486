
#header {
  display: block;
  height: 120px;
  margin: 0;
  padding: 0 13px 0 0;
  position: relative;
  z-index: $left-panel-zindex+1;
  div.open {
    font-weight: bold;
  }
  div.pull-right {
    margin-top: 5px;
  }

  #logo-group span {
    display: inline-block;
    height: 39px;
    float: left;
  }

  #logo {
    display: inline-block;
    margin: 0px;
    padding: 0px;
    &:hover {
      img {

      }
    }
    img {
      padding: 0px;
      height: auto;
    }
  }



  .bg_lang {
    background: url('/assets/img/icon/lang.png') no-repeat;
    background-size: 30px;
    width: 30px;
    height: 30px;
    padding: 5px 0px 0px 6px;
    &:hover {
      img {
        opacity: 0.8;
      }
    }
  }
  ul.icon_header {
    margin-left: 0px;
    padding-left: 3px;
    padding-top: 16px;
    img {
      width: 30px;
    }
    li {
      &:hover {
        img {
          opacity: 0.8;
        }
      }
    }

  }
  .login_info{
    position: absolute;
    right:10px;
    top:60px;
    a{
      color:$gray-lighter;
      &:hover{
        color:#FFF;
      }
    }
  }

  .header-dropdown-list {
    list-style: none;
    float: right;
    position: relative;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    margin-left: 10px;
    & li {
      display: inline-block;
      padding-right: 5px;
      & .dropdown-toggle {
        margin-top: 16px;
        display: block;
      }
    }
  }

  .header-search input[type=text] {
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    min-width: 200px;
    width: 100%;
    height: 30px;
    padding: 0 10px;
    outline: 0;
    border-width: 1px;
    border-style: solid;
    border-radius: 0;
    border-color: #bfbfbf;
    background: #fff;
    color: $darken;
    appearance: normal;
    -moz-appearance: none;
    -webkit-appearance: none;
    margin-top: 10px;
    line-height: normal;
  }

  .header-search button {
    background: 0 0;
    border: none;
    color: #6D6A69;
    font-size: 17px;
    height: 30px;
    line-height: 30px;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 10px;
    width: 30px;
    z-index: 2;
  }

  #cancel-search-js {
    position: absolute;
    display: none;
    background: $red;
    z-index: 3;
    color: #fff;
    padding: 0;
    right: 10px;
    top: 10px;
    text-align: center;
    height: 29px;
    line-height: 29px;
    width: 30px;
    font-size: 17px;
    text-decoration: none !important;
  }

  .nav-pills {
    li {
      margin-right: 20px;
      &:hover {
        background: none;
      }
      a {
        background-color: $white;

        border: 1px solid $blueMega;
        color: $gray;
        //border-radius: 0px;
        display: inherit;
        height: auto;
        margin-top: 8px;
        padding: 5px 10px;
        font-size: 15px;

        &:hover {
          background: $blueMega;
          color: $white;

        }
      }
      &.active {
        a {
          background-color: $blueMega;
          color: $white;
        }
      }
    }

    //line-height: 30px;
  }

}
.menu_header {
  h1 {
    margin-left: 50px;
    color:$white;
    font-size: 200%;
  }
  ul {
    margin-left: 50px;
    margin-top: 10px;
    li {
      min-width: 50px;
      position: relative;
      &:hover {
        cursor: pointer;
        img {
          opacity: 0.8;
        }
        div {
          display: block;
          @include transition(all .5s ease-in)
        }
      }
      display: inline-block;
      padding-bottom: 15px;
      margin-right: 25px;
      padding-right: 20px;
      border-right: 1px solid #6e7781;

      img {
        height: 45px;
      }
      &:last-child {
        border: none;
      }
      background: url('/assets/img/icon/menu_icon_child.png') no-repeat 50% 80%;
      div {
        position: absolute;
        top: 60px;
        display: none;
        min-width: 200px;
        ul {
          background-color: rgba(255, 255, 255, 0.8);
          &:before {
            border: none;
          }
          margin: 0px;
          padding: 0px;

          li {
            &:before {
              border: none;
            }
            display: block;
            padding: 4px 0px 4px 10px;
            margin: 0px;
            background: none;
            border: none;
            &:hover {
              background: #535F6A;
              i{color: $gray-lighter}
              span{color: $gray-lighter}
            }

            a {
              padding: 0 0 5px 10px;
            }
          }
        }
      }

    }

  }
  div ul li a:active {
    background: none !important;
  }
}