/*
 * jarvismetro TILE
 */

.jarvismetro-tile {
  float:left;
  display: block;
  background-color: $white;
  width: 100px;
  height: 70px;
  cursor: pointer;
  box-shadow: inset 0px 0px 1px #FFFFCC;
  border:1px dotted #C5C5C5;
  text-decoration: none;
  color: $white;
  position: relative;
  font-weight: 300;
  font-smooth: always;
  margin: 0 10px 20px 0;
  padding:5px;
  position: relative;
}

.jarvismetro-tile:hover {
  z-index: 10;
  -webkit-transform: scale(1.07);
  -moz-transform: scale(1.07);
}

.jarvismetro-tile.big-cubes {
  width:120px;
  height:120px;
}

.jarvismetro-tile.double {
  width: 249px;
}

.jarvismetro-tile:active {
  top: 1px;
  left: 1px;
}

.jarvismetro-tile .iconbox {
  text-align:center;
}

.jarvismetro-tile .iconbox i {
  display:block;
  margin: 15px auto 0;
  height: 75px;
}
.jarvismetro-tile .iconbox span {
  display:block;
  text-align:left;
}

.jarvismetro-tile .iconbox span > span {
  position: absolute;
  right: -3px;
  bottom: -3px;
  border-radius: 50%;
  padding: 5px 5px;
  border: 1px solid $white;
}

#shortcut .selected {
  border: 1px solid $blueSky !important;
}

#shortcut .selected {
  position:relative;
}

#shortcut .selected:before {
  display:block;
  position: absolute;
  content: "\f00c";
  color: $white;
  right: 4px;
  top: 3px;
  font-family: FontAwesome;
  z-index: 2;
}

#shortcut .selected:after {
  display:block;
  width: 0;
  height: 0;
  border-top: 35px solid $blueSky;
  border-left: 35px solid rgba(red($black), green($black), blue($black), 0);
  position: absolute;
  display: block;
  right: 0;
  content: "";
  top: 0;
  z-index: 1;
}

/*
 * SHORT CUT
 */

#shortcut {
  display:block;
  position:absolute;
  top:0px;
  left:0;
  height:auto;
  width:100%;
  background-color:#33302F;
  background-color: rgba(red($black), green($black), blue($black), 0.85);
  z-index: $ajax-dropdown-zindex+2;
  display:none;
  color:$white;
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  box-sizing:border-box;
}

#shortcut ul {
  padding: 15px 20px 10px;
  list-style:none;
  margin:0;
  box-shadow: 0px 4px 10px rgba(red($black), green($black), blue($black), 0.3);
  -moz-box-shadow: 0px 4px 10px rgba(red($black), green($black), blue($black), 0.3);
  border-bottom: 1px solid #423F3F;
}

#shortcut ul li {
  display:inline-block;
  width:auto;
}

#shortcut ul li .jarvismetro-tile  {
  margin:0px 3px 3px;
  border:none;
  border-radius: 0px;
  -moz-border-radius:0px;
  -webkit-border-radius:0px;
}

#shortcut ul li .jarvismetro-tile:hover {
  color:$white;
  text-decoration:none;
}

#shortcut ul li .jarvismetro-tile:active, #shortcut ul li .jarvismetro-tile:focus {
  left:0;
  top:0;
}

.shortcut-on #response-btn {
  display:none !important;

}

.shortcut-on #main .navbar, .shortcut-on #left-bar .navbar {
  border:none !important;
}