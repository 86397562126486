@mixin border() {
  border: 2px solid $black;
}

.border-danger {
  @include border();
  border-color: $brand-danger;
}

.border-success {
  @include border();
  border-color: $brand-success;
}

.border-primary {
  @include border();
  border-color: $brand-primary;
}

.border-warning {
  @include border();
  border-color: $brand-warning;
}

.border-info {
  @include border();
  border-color: $brand-info;
}