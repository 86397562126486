/*
 * SEARCH RESULTS
 */

.search-results {
	padding: 18px 5px;

  + .search-results {
	  border-top: 1px dashed #e3e3e3;
  }
  >:first-child {
	  margin-bottom: 4px;
	  font-weight: 400;
    a {
	    text-decoration: underline;
    }
  }
  .url {
	  font-style: normal;
	  font-size: 14px;
  }
  img {
	  display: inline-block;
	  margin-top: 4px;
	  margin-right: 4px;
	  width: 80px;
  }
  > div {
	  display: inline-block;
	  vertical-align: top;
  }
  .note {
	  margin: 0;
	  line-height: normal; 	
    a {
	    text-decoration: none !important;
	    color: $gray-dark;
	    &:hover {
	      color: #ed1c24;
      }
    }
  }
}

