#left-panel {
  top:100px;
}

.login-info a {
  text-decoration: none!important;
  color: #c0bbb7;
  display: inline-block;
  margin-top: 10px;
}

